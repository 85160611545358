import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeleteBySerialNoLD, LDGetAll } from "../api/LicenseDataAPI";

import styles from "../assets/styles/licenseManagerScreen.module.css";
import Loader from "../components/Loader";
import GoBackButton from "../components/GoBackButton";
import ConfirmationDialog from "../components/ConfirmationDialogProps";
import api from "../middleware/api";
import Logo from "../components/Logo";

interface License {
  customer: string | null;
  controllerName: string | null;
  serialNo: string; // Required
  simcardSerialNo: string | null;
  imsi: string | null;
  ipaddress: string | null;
  msisdn: string | null;
  modemFirmware: string | null;
  latitude: string | null;
  longitude: string | null;
  boardType: string | null;
  remark: string | null;
}

const LicenseManagerScreen: React.FC = () => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        setLoading(true);
        const LDGetAllResponse = await LDGetAll(
          location.state?.itemsPerPage || itemsPerPage,
          location.state?.currentPage || currentPage,
          location.state?.searchTerm || searchTerm
        );
        if (LDGetAllResponse && LDGetAllResponse.data) {
          location.state?.currentPage &&
            setCurrentPage(location.state.currentPage);
          location.state?.itemsPerPage &&
            setItemsPerPage(location.state.itemsPerPage);
          location.state?.searchTerm &&
            setSearchTerm(location.state.searchTerm);
          if (location.state?.itemsPerPage) location.state.itemsPerPage = false;
          if (location.state?.currentPage) location.state.currentPage = false;
          if (location.state?.searchTerm) location.state.searchTerm = false;
          setLicenses(LDGetAllResponse.data.data);
          setTotalPages(LDGetAllResponse.data.totalPages);
          setTotalItems(LDGetAllResponse.data.totalItems);
        }
        window.history.replaceState({}, "");
      } catch (error) {
        console.error("Error fetching licenses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLicenses();
  }, [currentPage, itemsPerPage, searchTerm, location]);

  const [kek, setKek] = useState<string>("");
  const handleOpenDialog = (serialNo: string) => {
    setDialogOpen(true);
    setKek(serialNo);
  };

  const handleDelete = async () => {
    try {
      await DeleteBySerialNoLD(kek);
      setLicenses(licenses.filter((license) => license.serialNo !== kek));
    } catch (error) {
      console.error("Error deleting license:", error);
    } finally {
      setDialogOpen(false);
    }
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleEdit = (serialNo: string) => {
    navigate(`/iot-license-manager/${serialNo}`, {
      state: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        searchTerm: searchTerm,
      },
    });
  };

  const downloadUrlRef = useRef<string | null>(null);
  const handleGetQRCode = async (
    serialNo: string,
    msisdn: string | null,
    ipaddress: string | null
  ) => {
    serialNo = serialNo.startsWith("'") ? serialNo.slice(1) : serialNo;
    msisdn = msisdn?.startsWith("'") ? msisdn.slice(1) : msisdn;
    if (
      !msisdn?.startsWith("'389") &&
      (ipaddress?.trim().length ?? "".length) < 7
    )
      return;
    try {
      const response = await api.get("api/QRCode/generateAndGetQRCode", {
        params: { tekst: `${serialNo}-${msisdn}` },
        responseType: "blob",
      });

      if (downloadUrlRef.current) {
        URL.revokeObjectURL(downloadUrlRef.current);
      }

      const qrCodeBlobUrl = URL.createObjectURL(response.data);
      downloadUrlRef.current = qrCodeBlobUrl;

      const a = document.createElement("a");
      a.href = qrCodeBlobUrl;
      a.download = `${serialNo}.png`;
      a.click();
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const renderPaginationButtons = () => {
    return (
      <div className={styles.pag}>
        <p>
          Page: {currentPage} / {totalPages} Items: {totalItems}
        </p>
        <div>
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage <= 1}
            className={currentPage <= 1 ? styles.buttonDis : styles.button1}
          >
            Back
          </button>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(parseInt(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage >= totalPages}
            className={
              currentPage >= totalPages ? styles.buttonDis : styles.button1
            }
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const currentId = location.state?.id || "";
  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <div className={isDialogOpen ? styles.screenUnavaible : styles.screen}>
      <div className={styles.wrapper3}>
        <div className={styles.wrapper4}>
          <Logo className={["lcs"]} />
          <h1 className={styles.h1}>IoT License Manager</h1>
          <div className={styles.group}>
            <svg className={styles.icon} aria-hidden="true" viewBox="0 0 24 24">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              placeholder="Search"
              type="search"
              className={styles.input}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <div className={styles.kekwx}>
          {renderPaginationButtons()}
          <GoBackButton />
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Customer</th>
            <th>Controller Name</th>
            <th>ICCID</th>
            <th>IMSI</th>
            <th>IP Address</th>
            <th>MSISDN</th>
            <th>Modem Firmware</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Board Type</th>
            <th>Remark</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td id={styles.clear} colSpan={13}>
                <Loader className={["centerOfScreen"]} />
              </td>
            </tr>
          ) : licenses.length > 0 ? (
            <>
              {licenses.map((license) => (
                <tr
                  key={license.serialNo}
                  id={currentId === license.serialNo ? styles.kek : ""}
                >
                  <td>
                    {license.serialNo?.startsWith("'")
                      ? license.serialNo.slice(1)
                      : license.serialNo || "N/A"}
                  </td>
                  <td>
                    {license.customer?.startsWith("'")
                      ? license.customer.slice(1)
                      : license.customer || "N/A"}
                  </td>
                  <td>
                    {license.controllerName?.startsWith("'")
                      ? license.controllerName.slice(1)
                      : license.controllerName || "N/A"}
                  </td>
                  <td>
                    {license.simcardSerialNo?.startsWith("'")
                      ? license.simcardSerialNo.slice(1)
                      : license.simcardSerialNo || "N/A"}
                  </td>
                  <td>
                    {license.imsi?.startsWith("'")
                      ? license.imsi.slice(1)
                      : license.imsi || "N/A"}
                  </td>
                  <td>
                    {license.ipaddress?.startsWith("'")
                      ? license.ipaddress.slice(1)
                      : license.ipaddress || "N/A"}
                  </td>
                  <td>
                    {license.msisdn?.startsWith("'")
                      ? license.msisdn.slice(1)
                      : license.msisdn || "N/A"}
                  </td>
                  <td>
                    {license.modemFirmware?.startsWith("'")
                      ? license.modemFirmware.slice(1)
                      : license.modemFirmware || "N/A"}
                  </td>
                  <td>
                    {license.latitude?.startsWith("'")
                      ? license.latitude.slice(1)
                      : license.latitude || "N/A"}
                  </td>
                  <td>
                    {license.longitude?.startsWith("'")
                      ? license.longitude.slice(1)
                      : license.longitude || "N/A"}
                  </td>
                  <td>
                    {license.boardType?.startsWith("'")
                      ? license.boardType.slice(1)
                      : license.boardType || "N/A"}
                  </td>
                  <td>
                    {license.remark?.startsWith("'")
                      ? license.remark.slice(1)
                      : license.remark || "N/A"}
                  </td>
                  <td>
                    <button onClick={() => handleEdit(license.serialNo)}>
                      Edit
                    </button>
                    <button onClick={() => handleOpenDialog(license.serialNo)}>
                      Delete
                    </button>
                    <button
                      id={
                        !license.msisdn?.startsWith("'389") &&
                        (license.ipaddress?.trim().length ?? "".length) < 7
                          ? styles.nope
                          : ""
                      }
                      onClick={() =>
                        handleGetQRCode(
                          license.serialNo,
                          license.msisdn,
                          license.ipaddress
                        )
                      }
                    >
                      QR
                    </button>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td id={styles.kek2} colSpan={13}>
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div id={styles.lol}>
        <ConfirmationDialog
          isOpen={isDialogOpen}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          title="Are you sure?"
          message={`License with serial number ${
            kek.startsWith("'") ? kek.slice(1) : kek
          } will be deleted.`}
        />
      </div>
    </div>
  );
};

export default LicenseManagerScreen;
